var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "MultiSelectSelectedOption",
      attrs: { "aria-label": "Delete option", type: "button" },
      on: {
        click: [
          function($event) {
            _vm.$emit("click")
          },
          function($event) {
            $event.stopPropagation()
          }
        ]
      }
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("i", { staticClass: "lnr-cross MultiSelectSelectedOptionDelete" })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }