var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "MultiSelectWrapper",
        class: { "is-disabled": _vm.disabled, "is-invalid": !_vm.valid },
        attrs: { tabindex: _vm.searchable ? -1 : 0, role: "combobox" },
        on: {
          click: [
            function($event) {
              _vm.toggleMultiSelect()
            },
            function($event) {
              $event.stopPropagation()
            }
          ],
          blur: function($event) {
            _vm.searchable ? false : _vm.deactivate()
          },
          keyup: function($event) {
            if (
              !("button" in $event) &&
              _vm._k($event.keyCode, "esc", 27, $event.key, "Escape")
            ) {
              return null
            }
            _vm.deactivate()
          }
        }
      },
      [
        _c(
          "label",
          {
            staticClass: "MultiSelectLabel",
            class: { "is-empty": _vm.selectedOption === null }
          },
          [
            _c(
              "label",
              {
                staticClass: "FloatingLabel",
                class: { "is-active": _vm.isActive || _vm.optionsSelected }
              },
              [_vm._v("\n\t\t\t\t\t" + _vm._s(_vm.label) + "\n\t\t\t\t")]
            ),
            _vm._v(" "),
            _vm.isActive || _vm.optionsSelected
              ? _c("span", { staticClass: "MultiSelectPlaceholder" }, [
                  _vm._v(
                    "\n\t\t\t\t\t" + _vm._s(_vm.inputPlaceholder) + "\n\t\t\t\t"
                  )
                ])
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _c("div", {
          staticClass: "MultiSelectIcon",
          class: { "is-active": _vm.isActive }
        }),
        _vm._v(" "),
        _vm.isActive
          ? _c(
              "div",
              {
                ref: "dropDown",
                staticClass: "MultiSelectDropDown",
                attrs: { "aria-role": "listbox" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [
                _vm.searchable
                  ? _c("SearchBox", {
                      attrs: { searchPlaceholder: _vm.searchPlaceholder },
                      on: { searchUpdated: _vm.updateSearch }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.groups.length > 0
                  ? _c(
                      "div",
                      _vm._l(_vm.availableGroupedOptions, function(
                        group,
                        index
                      ) {
                        return _c("OptionGroup", {
                          key: index,
                          attrs: { groupData: group },
                          on: { optionSelected: _vm.selectOption }
                        })
                      })
                    )
                  : _c("Options", {
                      attrs: { optionData: _vm.availableOptions },
                      on: { optionSelected: _vm.selectOption }
                    })
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        this.multipleOptions
          ? _c(
              "div",
              { staticClass: "MultiSelectSelectedOptions" },
              _vm._l(_vm.selectedOptions, function(option, index) {
                return _c(
                  "SelectedOption",
                  {
                    key: index,
                    on: {
                      click: function($event) {
                        _vm.deleteOption(option)
                      }
                    }
                  },
                  [_vm._v("\n\t\t\t\t\t" + _vm._s(option.label) + "\n\t\t\t\t")]
                )
              })
            )
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _vm.valid && _vm.helperMessage !== ""
      ? _c("div", { staticClass: "HelperMessage" }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.helperMessage) + "\n\t\t")
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.valid && _vm.validationLabel !== ""
      ? _c("div", { staticClass: "ValidationLabel" }, [
          _vm._v("\n\t\t\t" + _vm._s(_vm.validationLabel) + "\n\t\t")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }