var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.groupData.options.length > 0
    ? _c(
        "ol",
        [
          _c("li", { staticClass: "MultiSelectGroupLabel" }, [
            _vm._v("\n\t\t" + _vm._s(_vm.groupData.label) + "\n\t")
          ]),
          _vm._v(" "),
          _vm._l(_vm.groupData.options, function(option, index) {
            return _c(
              "Option",
              {
                key: index,
                on: {
                  click: function($event) {
                    _vm.$emit("optionSelected", option)
                  }
                }
              },
              [
                option.icon
                  ? _c("i", {
                      staticClass: "MultiSelectOptionIcon",
                      class: option.icon
                    })
                  : _vm._e(),
                _vm._v("\n\t\t" + _vm._s(option.label) + "\n\t")
              ]
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }