(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue-property-decorator"));
	else if(typeof define === 'function' && define.amd)
		define(["vue-property-decorator"], factory);
	else if(typeof exports === 'object')
		exports["MultiSelect"] = factory(require("vue-property-decorator"));
	else
		root["MultiSelect"] = factory(root["vue-property-decorator"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_vue_property_decorator__) {
return 