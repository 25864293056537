var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ol",
    [
      _vm.optionData.length === 0
        ? _c(
            "Option",
            {
              attrs: { disabled: true },
              on: {
                click: function($event) {
                  _vm.$emit("optionSelected", _vm.option)
                }
              }
            },
            [_vm._v("\n\t\tNo available options\n\t")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.optionData, function(option, index) {
        return _c(
          "Option",
          {
            key: index,
            on: {
              click: function($event) {
                _vm.$emit("optionSelected", option)
              }
            }
          },
          [
            option.icon
              ? _c("i", {
                  staticClass: "MultiSelectOptionIcon",
                  class: option.icon
                })
              : _vm._e(),
            _vm._v("\n\t\t" + _vm._s(option.label) + "\n\t")
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }