var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "MultiSelectSearchBoxWrapper" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.searchQuery,
          expression: "searchQuery"
        }
      ],
      ref: "searchBox",
      staticClass: "MultiSelectSearchBox",
      class: _vm.searchQuery === "" ? "is-empty" : "",
      attrs: { placeholder: _vm.searchPlaceholder, type: "search" },
      domProps: { value: _vm.searchQuery },
      on: {
        keyup: function($event) {
          _vm.$emit("searchUpdated", _vm.searchQuery)
        },
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.searchQuery = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _c("i", { staticClass: "lnr-magnifier MultiSelectSearchBoxIcon" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }